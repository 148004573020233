import React from "react"
import { Link } from "gatsby"
import { navigate } from "gatsby-link"

function encode(data) {
	const formData = new FormData()

	for (const key of Object.keys(data)) {
		formData.append(key, data[key])
	}

	return formData
}

function ContactFormComponent() {
	const [state, setState] = React.useState({})

	const handleChange = e => {
		setState({ ...state, [e.target.name]: e.target.value })
	}

	const handleSubmit = e => {
		e.preventDefault()
		const form = e.target
		fetch("/", {
			method: "POST",
			body: encode({
				"form-name": form.getAttribute("name"),
				...state,
			}),
		})
			.then(() => navigate(form.getAttribute("action")))
			.catch(error => alert(error))
	}

	return (
		<section
			className="relative"
		>
			<form
				name="Kontakt"
				method="post"
				action="/kontakt/thank-you"
				data-netlify="true"
				data-netlify-honeypot="bot-field"
				onSubmit={handleSubmit}
			>
				{/* The `form-name` hidden field is required to support form submissions without JavaScript */}
				<input type="hidden" name="form-name" value="Kontakt" />
				<p hidden>
					<label>
						Don’t fill this out:
						<input name="bot-field" onChange={handleChange} />
					</label>
				</p>

        <div className="flex flex-col items-center">
          <h1 className="text-gray-800 font-light pb-2">Wir freuen uns über</h1>
		  <div className="border-b-3 relative border-secondary w-16 h-18 m-6"></div>
          <div className="h1 pb-10">
            <span className="text-gray-800">Ihre Nachricht:</span></div>
        </div>

        <div className="grid md:grid-row-2 w-auto">
          <div>
            
            <input
			  className="w-full mb-6 form-input"
			  placeholder="Vorname"
              id="first-name"
              type="text"
              onChange={handleChange}
              name="firstName"
              required
            />
          </div>
          <div>

            <input
			  className="w-full mb-6 form-input"
			  placeholder="Nachname"
              id="last-name"
              type="text"
              onChange={handleChange}
              name="lastName"
              required
            />
          </div>
          <div>

            <input
			  className="w-full mb-6 form-input"
			  placeholder="Telefon"
              id="tel"
              type="tel"
              onChange={handleChange}
              name="tel"
              required
            />
          </div>
        </div>

				<textarea
					className="w-full mb-6 form-textarea"
					id="message"
					placeholder="Was möchten Sie uns mitteilen?"
					rows="4"
					onChange={handleChange}
					name="message"
					required
				/>

				<div className="flex">
					<label className="flex items-center">
						<input type="checkbox" className="form-checkbox" required />
						<span className="ml-2 font-light text-grey-800">
							Ich bin mit den{" "}
							<Link to="/datenschutz/" title="Datenschutz" className="underline">
								Datenschutzrichtlinien
							</Link>{" "}
							einverstanden
						</span>
					</label>
				</div>

				<button className="mt-6 mx-24 px-8 py-2 text-sm font-semibold text-white bg-secondary border-b-2 hover:bg-gray-600">
					NACHRICHT ABSENDEN
				</button>
			</form>
		</section>
	)
}

export default ContactFormComponent
