import React from "react";
import { graphql } from "gatsby";
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import ContactForm from "../../../components/ContactForm"
import Address from "../../../components/addressElement"

import { GatsbyImage, getImage } from "gatsby-plugin-image"


function ContactPage ({ data }) {
  const image = getImage(data.mapImage.childImageSharp.gatsbyImageData)
  return (
    <Layout
      headerTitle="Unsere Standorte"
      headerSub='Kontakt'
      pageNav='immo'
      bgImg='kontakt'
      >
      <Seo title="Kontakt"/>
    <div className="max-w-6xl mx-auto">

    <iframe title='Standorte' className="relative hidden object-contain w-full -mt-12 md:block md:h-720 h-400 md:-mt-24" src="https://snazzymaps.com/embed/280556" />
          <GatsbyImage 
            image={image}
            className="relative object-contain -mt-12 bg-black md:hidden lg:h-720 h-400 md:-mt-24"
            alt=""
          />


        <div className="max-w-lg px-6 py-24 mx-auto text-base uppercase">
          <Address className='text-center' center='w-6 mx-auto' location='Thusis' tel='+41 (0)81 650 00 30' email='immobilien@hossmann.com' name='Hossmann Immobilien AG' address1='Compognastrasse 33' address2='CH-7430 Thusis'/>
        </div>

      <div className="max-w-lg mx-auto md:pt-16">
          <ContactForm/>
      </div>
    
    </div>

    </Layout>
  );
 }
export default ContactPage;

export const pageQuery = graphql`{
  mapImage: file(relativePath: {eq: "map.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`